import React from 'react'
import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme)=>({
    siteDescription:{
        padding:"16px"
    },
    cardContainer:{
        height:"200px",
        width:"100%",
        padding:"15px",
        display:"flex",
        flexDirection:"column",
        backgroundColor:"red"
    },
    cardTitle:{
        height:"150px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        color:"gray"
    },
    cardActionButton:{
        display:"flex",
        justifyContent:"flex-end"
    }
}))

export default useStyles;