import React from 'react'
import { AppBar, Toolbar, Typography } from '@mui/material'

export default function NavBar() {
  return (
        <AppBar position='relative'>
          <Toolbar>
            <Typography variant='h5'>
              Brain Booster
            </Typography>
          </Toolbar>
        </AppBar>
  )
}
