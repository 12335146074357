export const AppData = [
    {
        name:"Javascript",
        domain:"Front end",
        topics:[
            "Closures in JavaScript",
            "Callback functions",
            "Hoisting",
            "Scope in JavaScript",
            "Promises",
            "Asynchronous JavaScript",
            "Arrow functions",
            "ES6 features",
            "Event handling in JavaScript",
            "Prototypal inheritance",
            "DOM manipulation",
            "Higher-order functions",
            "The \"this\" keyword in JavaScript",
            "Error handling in JavaScript",
            "Module systems (CommonJS, ES Modules)",
            "JavaScript data types",
            "Functional programming concepts in JavaScript",
            "The event loop in JavaScript",
            "JavaScript frameworks (React, Angular, Vue.js)",
            "JavaScript tooling (Webpack, Babel, ESLint)",
            "Object-oriented programming in JavaScript",
            "Lexical scoping",
            "Template literals",
            "Array methods (map, filter, reduce)",
            "Rest and spread operators",
            "Destructuring assignment",
            "JavaScript design patterns",
            "Memoization",
            "IIFE (Immediately Invoked Function Expression)",
            "CORS (Cross-Origin Resource Sharing)",
            "ES6 classes",
            "Web APIs (Fetch API, Local Storage, Web Workers)",
            "WebSockets",
            "AJAX (Asynchronous JavaScript and XML)",
            "JSON (JavaScript Object Notation)",
            "Testing in JavaScript (Jest, Mocha, Jasmine)",
            "Browser compatibility",
            "Progressive Web Apps (PWA)",
            "Service workers",
            "Single Page Applications (SPA)",
            "Web security (Cross-Site Scripting, Cross-Site Request Forgery)",
            "Memory management in JavaScript",
            "Functional reactive programming with RxJS",
            "Package managers (npm, Yarn)",
            "Browser Developer Tools",
            "Code optimization techniques",
            "Debugging JavaScript",
            "Accessibility in web development",
            "Performance optimization",
            "Front-end build tools (Gulp, Grunt)",
            "State management in JavaScript applications",
            "JavaScript for data visualization (D3.js, Chart.js)"
        ]
    },
    {
        name:"React",
        domain:"Front end",
        topics:[
            "JSX (JavaScript XML)",
            "Components in React",
            "Props and state in React",
            "React lifecycle methods",
            "Class components vs. functional components",
            "React Hooks",
            "useState hook",
            "useEffect hook",
            "useContext hook",
            "Custom hooks in React",
            "Rendering in React",
            "Conditional rendering in React",
            "Lists and keys in React",
            "Forms in React",
            "Controlled vs. uncontrolled components",
            "React Router",
            "React Context API",
            "Higher-order components (HOCs)",
            "React Fragments",
            "Error boundaries in React",
            "React testing library",
            "Redux for state management",
            "React performance optimization",
            "Code splitting in React",
            "Server-side rendering (SSR) with React",
            "React Native for mobile development",
            "Next.js for server-side rendering",
            "Gatsby.js for static site generation",
            "Styled-components for styling in React",
            "Material-UI for UI components in React",
            "React Bootstrap for UI components",
            "React props drilling",
            "Immutable.js for immutable data structures",
            "React lazy loading",
            "React.memo for memoization",
            "React portals",
            "React suspense",
            "React animations",
            "React design patterns",
            "React and SEO",
            "React performance monitoring",
            "GraphQL with React",
            "Apollo Client for state management in React",
            "Redux Saga for side effects in React",
            "React SSR hydration",
            "React concurrent mode",
            "Server Components in React",
            "React server components",
            "React SSR frameworks (Next.js, Gatsby.js)",
            "React state management alternatives (MobX, Recoil)",
            "Error handling in React applications",
            "React best practices and common pitfalls"
        ]
    },
    {
        name:"HTML",
        domain:"Front end",
        topics:[
            "Structure of an HTML document",
            "HTML elements and tags",
            "Semantic HTML",
            "HTML attributes",
            "HTML headings",
            "HTML paragraphs",
            "HTML links (anchor tags)",
            "HTML images",
            "HTML lists (ordered and unordered)",
            "HTML tables",
            "HTML forms",
            "HTML input types (text, password, email, etc.)",
            "HTML buttons",
            "HTML radio buttons and checkboxes",
            "HTML select dropdowns",
            "HTML labels",
            "HTML meta tags",
            "HTML comments",
            "HTML entities and special characters",
            "HTML character encoding",
            "HTML block vs. inline elements",
            "HTML div and span elements",
            "HTML classes and IDs",
            "HTML IDs vs. classes",
            "HTML global attributes",
            "HTML multimedia elements (audio, video)",
            "HTML canvas element",
            "HTML SVG (Scalable Vector Graphics)",
            "HTML5 new semantic elements (header, footer, article, section, etc.)",
            "HTML5 form validation",
            "HTML5 local storage",
            "HTML5 web storage",
            "HTML5 geolocation",
            "HTML5 drag and drop",
            "HTML5 web sockets",
            "HTML5 responsive images",
            "HTML5 picture element",
            "HTML5 responsive design",
            "HTML5 semantic layout techniques",
            "HTML5 audio and video APIs",
            "HTML5 offline web applications",
            "HTML5 new input types (date, time, range, etc.)",
            "HTML5 web workers",
            "HTML5 canvas drawing API",
            "HTML5 accessibility (ARIA attributes)",
            "HTML5 SEO best practices",
            "HTML5 boilerplate",
            "HTML5 microdata and schema.org",
            "HTML5 browser support and compatibility",
            "HTML5 polyfills",
            "HTML5 new elements and attributes",
            "HTML5 best practices and common mistakes"
        ]
    },
    {
        name:"CSS",
        domain:"Front end",
        topics:[
            "CSS syntax and selectors",
            "CSS properties and property values",
            "CSS classes and IDs",
            "CSS specificity",
            "CSS box model",
            "CSS display property",
            "CSS positioning (static, relative, absolute, fixed)",
            "CSS floats",
            "CSS flexbox",
            "CSS grid",
            "CSS units (px, em, rem, %, etc.)",
            "CSS colors (hexadecimal, RGB, HSL)",
            "CSS background properties",
            "CSS text properties (font, size, weight, etc.)",
            "CSS borders",
            "CSS margins and padding",
            "CSS pseudo-classes (:hover, :active, :focus, etc.)",
            "CSS pseudo-elements (::before, ::after)",
            "CSS transitions",
            "CSS animations",
            "CSS transforms",
            "CSS gradients",
            "CSS box-shadow",
            "CSS media queries",
            "CSS responsive design techniques",
            "CSS vendor prefixes",
            "CSS preprocessors (Sass, Less)",
            "CSS frameworks (Bootstrap, Foundation)",
            "CSS reset vs. CSS normalize",
            "CSS specificity wars",
            "CSS inheritance",
            "CSS variables (custom properties)",
            "CSS naming conventions (BEM, SMACSS, OOCSS)",
            "CSS architecture methodologies (Atomic CSS, ITCSS)",
            "CSS optimization techniques",
            "CSS typography best practices",
            "CSS layout techniques (floats vs. flexbox vs. grid)",
            "CSS positioning hacks and tricks",
            "CSS feature queries",
            "CSS custom selectors",
            "CSS responsive images and videos",
            "CSS responsive tables",
            "CSS feature detection",
            "CSS frameworks for animations (Animate.css, Motion UI)",
            "CSS accessibility best practices",
            "CSS browser compatibility issues",
            "CSS browser prefixes and fallbacks",
            "CSS critical path optimization",
            "CSS performance profiling and optimization",
            "CSS best practices and common mistakes",
            "CSS variables in JavaScript (CSS-in-JS)",
            "CSS future features and specifications"
        ]
    },
    {
        name:"Express JS",
        domain:"Back end",
        topics:[
            "Introduction to Express.js",
            "Setting up a basic Express.js server",
            "Routing in Express.js",
            "Route parameters in Express.js",
            "Express.js middleware",
            "Error handling middleware in Express.js",
            "Static files serving in Express.js",
            "Template engines with Express.js (e.g., EJS, Pug)",
            "Handling HTTP requests (GET, POST, PUT, DELETE) in Express.js",
            "Express.js request object",
            "Express.js response object",
            "Express.js next function",
            "Express.js query parameters",
            "Express.js form handling",
            "File uploads with Express.js",
            "Cookies and sessions in Express.js",
            "Authentication in Express.js",
            "Passport.js integration with Express.js",
            "JSON Web Tokens (JWT) in Express.js",
            "Express.js security best practices",
            "Cross-Origin Resource Sharing (CORS) in Express.js",
            "Rate limiting in Express.js",
            "Database integration with Express.js (e.g., MongoDB, MySQL)",
            "ORM/ODM with Express.js (e.g., Mongoose, Sequelize)",
            "Connecting to databases asynchronously in Express.js",
            "Handling database errors in Express.js",
            "Express.js middleware for authentication and authorization",
            "Role-based access control (RBAC) in Express.js",
            "Express.js error handling best practices",
            "Logging in Express.js",
            "Unit testing Express.js applications",
            "Integration testing Express.js applications",
            "Test-driven development (TDD) with Express.js",
            "Debugging Express.js applications",
            "Performance optimization in Express.js",
            "Caching in Express.js",
            "Deployment strategies for Express.js applications",
            "Continuous integration (CI) for Express.js projects",
            "Dockerizing Express.js applications",
            "Microservices architecture with Express.js",
            "Building RESTful APIs with Express.js",
            "Versioning RESTful APIs in Express.js",
            "API documentation with Express.js (e.g., Swagger)",
            "WebSocket integration with Express.js",
            "Real-time communication with Express.js (e.g., Socket.IO)",
            "GraphQL integration with Express.js",
            "Serverless architecture with Express.js",
            "Progressive Web Apps (PWA) with Express.js",
            "Express.js best practices and patterns",
            "Express.js community resources and libraries",
            "Express.js performance monitoring and profiling",
            "Express.js future trends and developments"
        ]
    },
    {
        name:"Node JS",
        domain:"Back end",
        topics:[
            "Introduction to Node.js",
            "Setting up Node.js environment",
            "Node.js package management (npm)",
            "Creating a basic Node.js server",
            "Node.js modules and require()",
            "Event-driven programming in Node.js",
            "Asynchronous programming in Node.js",
            "Callback functions in Node.js",
            "Promises in Node.js",
            "Async/Await in Node.js",
            "Error handling in Node.js",
            "File system operations in Node.js",
            "Working with streams in Node.js",
            "Buffers in Node.js",
            "Debugging Node.js applications",
            "Testing Node.js applications (Unit tests, Integration tests)",
            "Express.js framework for Node.js",
            "RESTful API development with Node.js",
            "WebSockets with Node.js (Socket.IO)",
            "Authentication in Node.js applications",
            "Authorization in Node.js applications",
            "Security best practices in Node.js",
            "Database integration in Node.js (MongoDB, MySQL, PostgreSQL)",
            "ORM/ODM libraries in Node.js (Mongoose, Sequelize)",
            "SQL injection prevention in Node.js",
            "Cross-site scripting (XSS) prevention in Node.js",
            "Cross-site request forgery (CSRF) prevention in Node.js",
            "Middleware in Node.js applications",
            "Web scraping with Node.js",
            "File uploads in Node.js applications",
            "Sending emails with Node.js",
            "Working with dates and times in Node.js",
            "Image processing in Node.js",
            "Real-time applications with Node.js",
            "Microservices architecture with Node.js",
            "Serverless architecture with Node.js (AWS Lambda, Azure Functions)",
            "GraphQL with Node.js (Apollo Server)",
            "Building CLI (Command Line Interface) applications with Node.js",
            "Profiling and optimizing Node.js applications",
            "Memory management in Node.js",
            "Scalability and load balancing in Node.js applications",
            "Monitoring and logging in Node.js applications",
            "Error tracking and reporting in Node.js applications",
            "Containerization with Node.js (Docker)",
            "Deployment strategies for Node.js applications",
            "Continuous integration and deployment (CI/CD) for Node.js",
            "Performance benchmarking in Node.js",
            "Server-side rendering with Node.js (Next.js)",
            "Progressive Web Apps (PWA) with Node.js",
            "IoT (Internet of Things) applications with Node.js",
            "WebAssembly with Node.js",
            "Emerging trends and future of Node.js"
        ]
    },
    {
        name:"XML HTTP Requests",
        domain:"Back end",
        topics:[
            "Introduction to XMLHttpRequest (XHR)",
            "Making asynchronous requests with XHR",
            "Creating a new XHR object",
            "Setting up a basic XHR request",
            "Sending an XHR request",
            "Handling XHR response",
            "Parsing XHR response data",
            "Asynchronous vs. synchronous XHR requests",
            "XHR methods (GET, POST, PUT, DELETE)",
            "Configuring XHR request headers",
            "Sending data with XHR requests (URL parameters, form data, JSON)",
            "Handling XHR request errors",
            "Aborting XHR requests",
            "XHR event listeners (onload, onerror, onabort)",
            "Managing multiple XHR requests",
            "Cross-origin XHR requests (CORS)",
            "JSONP (JSON with Padding) for cross-origin requests",
            "XHR with authentication (Basic Auth, Bearer Token)",
            "XHR with cookies and sessions",
            "XHR with custom authentication headers",
            "Uploading files with XHR (multipart/form-data)",
            "Monitoring XHR request progress",
            "Resuming XHR downloads",
            "Streaming data with XHR",
            "XHR with binary data (ArrayBuffer, Blob)",
            "XHR timeout management",
            "XHR caching strategies",
            "XHR response manipulation (modifying response headers, status codes)",
            "XHR request interception and modification",
            "Mocking XHR requests for testing",
            "Fetch API vs. XHR",
            "Promises vs. callbacks with XHR",
            "Asynchronous programming patterns with XHR",
            "Using XHR with ES6 modules",
            "Error handling best practices with XHR",
            "XHR security considerations (Cross-Site Request Forgery, Cross-Site Scripting)",
            "CSRF protection with XHR",
            "XSS prevention with XHR",
            "XHR performance optimization techniques",
            "XHR debugging tools (browser developer tools, network tab)",
            "XHR usage in modern web development frameworks (React, Vue.js, Angular)",
            "XHR integration with third-party libraries and APIs",
            "Server-sent events (SSE) vs. XHR",
            "WebSockets vs. XHR for real-time communication",
            "Server-side rendering (SSR) with XHR",
            "Progressive Web Apps (PWAs) and XHR",
            "Browser compatibility issues with XHR",
            "XHR polyfills for older browsers",
            "XHR usage in single-page applications (SPAs)",
            "Security best practices for XHR usage",
            "XHR usage in microservices architecture",
            "Emerging trends and future of XHR"
          ]
    }
]