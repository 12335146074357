import React from 'react'
import { Typography, Container } from '@mui/material'
import useStyles from '../Styles/Styles.js'
export default function Header() {
  const classes = useStyles()
  return (
    <main>
        <div>
            <Container maxwidth='sm' className={classes.siteDescription}>
                <Typography varient='h4' paragraph align='center'>
                    The "Brain Boosters" application aims to enhance cognitive skills through personalized brain training exercises. Users can track progress, engage in daily challenges, and interact with a community of like-minded individuals. With customizable difficulty levels and educational resources, it offers a comprehensive approach to improving memory, attention, and problem-solving abilities across multiple platforms. Additionally, integration with wearable devices ensures a holistic approach to brain health and fitness.
                </Typography>
            </Container>
        </div>
    </main>
      
  )
}
