import React, { useState } from 'react'
import { AppData } from './Data/AppData'
import { Typography, CssBaseline, Grid,  } from '@mui/material'
import NavBar from './components/NavBar'
import Header from './components/Header'
import Cards from './components/Cards'
import Picker from './components/Picker'
import './App.css'
export default function App() {
  const [showPicker, setShowPicker] = useState(false);
  const [pickedData, setPickedData] = useState(null);

  const handleButtonClick = (data) => {
    setPickedData(data);
    setShowPicker(true);
  };

  return (
    <div className='App'>
        <CssBaseline />
        <NavBar />
        <Header />
        {showPicker ?(
            <Picker pickedData={pickedData}/>
        ):(
          <Grid container spacing={4}>
            {
              AppData.map((data, id)=>(
                <Grid item key={id} lg={3} md={4} sm={6} xs={12} padding={0}>
                  <Cards data={data} onButtonClick={() => handleButtonClick(data)} />
                </Grid>
              ))
            }
          </Grid>
        )}
    </div>
        
  )
}