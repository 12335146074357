import React from 'react'
import { Card, CardContent, Typography, Button, CardActions, CardHeader} from '@mui/material'
import useStyles from '../Styles/Styles.js'

export default function Cards(props) {
    const handleButtonClick = () => {
        props.onButtonClick(props.data); // Pass data to onButtonClick
    };
    const classes = useStyles()
  return (
    <> 
        <Card className={classes.cardContainer}>
            <div className={classes.cardTitle}>
                <Typography variant='h6'>{props.data.name}</Typography>
            </div>
            <div className={classes.cardActionButton}>
                <Button variant='contained' onClick={handleButtonClick}>
                    Start
                </Button>
            </div>
        </Card>
    </>
  )
}
