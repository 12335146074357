import { Button, Grid, TextField, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import '../Styles/Picker.css'
export default function Picker({ pickedData }) {
    const [participantNames, setParticipantNames] = useState([]);
    const [pickedTopic, setPickedTopic] = useState('');
    const [pickedParticipant, setPickedParticipant] = useState('');
    const [isPicked, setIsPicked] = useState(false);
    const topics = pickedData.topics
    
    const handleTextFieldChange = (event) => {
        const names = event.target.value.split('\n');
        setParticipantNames(names);
      };
    const handlePickButtonClick = () => {
        const randomTopic = topics[Math.floor(Math.random() * topics.length)];
        const randomParticipant = participantNames[Math.floor(Math.random() * participantNames.length)];
        setPickedTopic(randomTopic);
        setPickedParticipant(randomParticipant);
        setIsPicked(true);
    };
  return (
    <div className='picker-container'>
        <div className='picker-title'>
            <Typography variant='h4' gutterBottom={1}>
                {pickedData.name}
                {console.log(topics)}
                {console.log(participantNames)}
            </Typography>
        </div>
        <Grid container className='picker-content'>
            <Grid item className='picker-announce' lg={8} flexDirection={"column"} alignContent={"center"} padding={8}>
                <Typography variant='h3'>
                    {isPicked ? pickedTopic : ''}
                </Typography>
                <Typography variant='h6'>
                    {isPicked ? "By "+pickedParticipant : ''}
                </Typography>
            </Grid>
            <Grid item className='picker-participants-panel' lg={4} flexDirection={"column"}  padding={4}>
                <div className='participants-panel-title'>
                    <Typography variant='h6' gutterBottom color={"GrayText"}>
                        Participants
                    </Typography>
                </div>
                <div className='participants-panel-input'>
                    <TextField
                        placeholder="List with new line seperator"
                        padding={0}
                        multiline
                        rows={12}
                        size='small'
                        fullWidth
                        onChange={handleTextFieldChange}
                    />
                </div>
                <div className='participants-panel-action'>
                    <Button variant='contained' onClick={handlePickButtonClick}>
                        Pick
                    </Button>
                </div>
            </Grid>
        </Grid>
    </div>
  )
}
